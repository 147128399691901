import React from 'react'
import styled from 'styled-components'

// import font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

library.add(faCheckCircle)

const ShipmentTrackerIndicatorBooked = styled.div`
    max-width: 1360px;
    margin: 0 auto;
    // 📰
    display: grid;
    grid-column-gap: 6px;
    grid-template-rows: minmax(1fr, 1fr) 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
    "cg_ordered cg_booked cg_checked_in cg_picked_up cg_delivered";

    .es-cg-ordered,
    .es-cg-booked,
    .es-cg-checked-in,
    .es-cg-picked-up, 
    .es-cg-delivered {
      // 🎛
      padding-top: 10px;
      padding-bottom: 10px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-align: center;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
      background-color: #e7edf0;
      border: solid 1px #93afbf;
      color: #93afbf;
      &.is-current {
        background: #3daf2c;
        border: solid 1px #3daf2c;
        color: #fff;
        border-radius: 2px;
      }
      &.is-complete {
        background: #ebffec;
        border: solid 1px #3daf2c;
        color: #3daf2c;
        border-radius: 2px;
      }
    }

    .es-cg-ordered {
      grid-area: cg_ordered; // 🗺
    }
    .es-cg-booked {
      grid-area: cg_booked; // 🗺
    }
    .es-cg-checked-in {
      grid-area: cg_checked_in; // 🗺
    }
    .es-cg-picked-up {
      grid-area: cg_picked_up; // 🗺
    }
    .es-cg-delivered {
      grid-area: cg_delivered; // 🗺
    }
`;


const PageComponentShipmentTrackerIndicator = () => {
  return (
    <ShipmentTrackerIndicatorBooked>
        <div class="es-cg-ordered is-complete">
        <FontAwesomeIcon icon="check-circle" style={{ color: '#3daf2c' }} /> Ordered
        </div>
        <div class="es-cg-booked is-current">
            Booked
        </div>
        <div class="es-cg-checked-in">
            Checked In
        </div>
        <div class="es-cg-picked-up">
            Picked Up
        </div>
        <div class="es-cg-delivered">
            Delivered
        </div>

    </ShipmentTrackerIndicatorBooked>
  )
}

export default PageComponentShipmentTrackerIndicator;